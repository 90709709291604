<template>
  <div class="wrapper">
    <h2 class="title">Content Generation</h2>
    <v-card>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card flat>
            <template v-show="item.id === tab">
              <component :is="item.component"></component>
            </template>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Generation from "@/components/contentGenerationTabs/Generation";
import Review from "@/components/contentGenerationTabs/Review";
import Analysis from "@/components/contentGenerationTabs/Analysis";

export default {
  name: "Index",
  data() {
    return {
      tab: null,
      items: [
        { id: 0, tab: "Generation", component: Generation },
        { id: 1, tab: "Review", component: Review },
        { id: 2, tab: "Analysis", component: Analysis },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
}
.title {
  width: 100%;
  margin-bottom: 30px;
}
</style>
