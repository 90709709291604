<template>
  <div class="config-info">
    <div class="config-swither">
      <v-radio-group v-model="radioGroup" @change="updateData()" row>
        <v-radio label="Jobs Status" value="jobs_status"></v-radio>
        <v-radio label="Posts Files" value="posts_list"></v-radio>
      </v-radio-group>
    </div>
    <div v-if="radioGroup === 'posts_list'">
      <div class="config-info__top">
        <div class="cell">Select Posts File:</div>
        <div class="cell">
          <v-select
            v-if="configList && configList.filenames"
            @change="getConfigFile()"
            v-model="selectedConfig"
            :items="configList.filenames"
          ></v-select>
        </div>
      </div>
      <div class="config-info__review" v-if="selectedConfig">
        <pre v-if="currentConfig && Object.keys(currentConfig).length !== 0">{{
          currentConfig
        }}</pre>
        <Loader v-else />
      </div>
      <div
        class="config-info__controls"
        v-if="selectedConfig"
        style="display: none"
      >
        <v-btn @click="dialog.isShow = true" elevation="2">Push to DB</v-btn>
      </div>
    </div>
    <div v-else>
      <div class="generation_jobs" v-if="generationJobs.length">
        <div class="top-row">
          <span class="title">Generation Jobs:</span>
          <div class="update">
            <v-btn @click="getGenerationJobs()" elevation="2">Update</v-btn>
          </div>
        </div>
        <div class="generation_jobs-list">
          <div class="table">
            <div class="table__header">
              <div class="item"></div>
              <div class="item">start_time</div>
              <div class="item">generation_state</div>
              <div class="item">config_path</div>
              <div class="item">output_filename</div>
              <div class="item">job_id</div>
            </div>
            <div class="table__body">
              <div
                class="table__body-row"
                v-for="item in generationJobs"
                :key="item.job_id"
              >
                <div class="item">
                  <v-checkbox
                    @change="selectedItemForPush($event, item.job_id)"
                    :disabled="item.generation_state !== 'job finished'"
                  ></v-checkbox>
                </div>
                <div class="item">{{ item.start_time }}</div>
                <div class="item">{{ item.generation_state }}</div>
                <div class="item">{{ item.config_path }}</div>
                <div class="item">
                  <span v-if="item.output_filename">{{
                    item.output_filename
                  }}</span>
                </div>
                <div class="item">{{ item.job_id }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="config-info__controls" v-if="jobsConfigForPush.length">
          <v-btn @click="dialog.isShow = true" elevation="2">Push to DB</v-btn>
        </div>
      </div>
    </div>
    <ModalDialog
      :isShow="dialog.isShow"
      :title="dialog.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="dialog.footerButtons"
      @success="saveToDb()"
      @close="dialog.isShow = false"
    >
      <template v-slot:description>
        You're about to save the data to the posts database
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ModalDialog from "@/components/global/ModalDialog";
import Loader from "@/components/global/Loader";

export default {
  name: "Review",
  components: {
    ModalDialog,
    Loader,
  },
  data: () => ({
    configList: [],
    selectedConfig: "",
    currentConfig: {},
    radioGroup: "jobs_status",
    generationJobs: null,
    jobsConfigForPush: [],
    dialog: {
      isShow: false,
      title: "Warning",
      footerButtons: [
        { name: "Ok", emitName: "success", btnClass: "violet" },
        { name: "Cancel", emitName: "close" },
      ],
    },
  }),
  async mounted() {
    await this.getConfigList();
    await this.getGenerationJobs();
  },
  methods: {
    ...mapActions("contentGeneration", ["contentGenerationData"]),
    async getConfigList() {
      const params = {
        gen_ui: {
          request_type: "get_generated_content_file_list",
        },
      };
      this.configList = await this.contentGenerationData(params);
    },
    async getConfigFile() {
      const params = {
        gen_ui: {
          request_type: "get_content_generation_config",
          config_path: this.selectedConfig,
        },
      };
      this.currentConfig = await this.contentGenerationData(params);
    },
    async getGenerationJobs() {
      const params = {
        gen_ui: {
          request_type: "get_generation_jobs",
        },
      };
      this.generationJobs = await this.contentGenerationData(params);
    },
    async saveToDb() {
      if (this.radioGroup === "jobs_status") {
        const params = {
          gen_ui: {
            request_type: "content_file_to_db",
            job_id: this.jobsConfigForPush,
          },
        };
        await this.contentGenerationData(params);
        await this.getGenerationJobs();
        this.dialog.isShow = false;
      } else {
        const params = {
          gen_ui: {
            request_type: "content_file_to_db",
            file_path: this.selectedConfig,
          },
        };
        await this.contentGenerationData(params);
        this.dialog.isShow = false;
      }
    },
    selectedItemForPush($event, job_id) {
      if ($event) {
        this.jobsConfigForPush.push(job_id);
      } else {
        this.jobsConfigForPush = this.jobsConfigForPush.filter((item) => {
          return item !== job_id;
        });
      }
    },
    async updateData() {
      await this.getConfigList();
      await this.getGenerationJobs();
    },
  },
};
</script>

<style scoped lang="scss">
.config-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  &__top {
    display: inline-flex;
    align-items: center;
    .cell {
      &:first-child {
        flex-shrink: 0;
      }
      &:last-child {
        width: 100%;
        max-width: 900px;
        margin-left: 20px;
      }
    }
  }
  &__controls {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ccc;
  }
  .generation_jobs {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 18px;
    }
    .top-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      padding-right: 20px;
      border-bottom: 1px solid #ccc;
    }
    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      &__header {
        width: 100%;
        display: grid;
        grid-template-columns: 60px 160px 160px 1fr 1fr 1fr;
        border-bottom: 1px solid #ccc;
        .item {
          padding-left: 20px;
          padding-bottom: 10px;
          padding-top: 10px;
          font-weight: 600;
          border-left: 1px solid #ccc;
          &:first-child {
            border-left: none;
          }
        }
      }
      &__body {
        display: flex;
        flex-direction: column;
        &-row {
          width: 100%;
          display: grid;
          grid-template-columns: 60px 160px 160px 1fr 1fr 1fr;
          border-bottom: 1px solid #ccc;
          .item {
            padding-left: 20px;
            padding-bottom: 10px;
            padding-top: 10px;
            border-left: 1px solid #ccc;
            word-break: break-all;
            font-size: 13px;
            padding-right: 10px;
            &:first-child {
              border-left: none;
            }
            ::v-deep {
              .v-input {
                padding-top: 0;
                margin-top: 0;
              }
              .v-messages {
                display: none;
              }
              .v-input__slot {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
