<template>
  <div class="charts-list" v-if="charts.length">
    <div class="charts-list__select">
      <v-select
        :items="charts"
        v-model="chart"
        item-text="name"
        return-object
        @change="updateValue()"
      ></v-select>
    </div>
    <div class="charts-list__item">
      <div class="item-name">{{ chart.name }}</div>
      <div class="item-data" v-if="chart.type === 'json'">
        <pre>{{ chart.jsonData }}</pre>
      </div>
      <div class="item-data" v-else>
        <img :src="chart.url" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Analysis",
  data: () => ({
    charts: [],
    chart: {},
  }),
  async created() {
    await this.getChartList();
  },
  methods: {
    ...mapActions("contentGeneration", ["fetchChartList", "fetchJsonData"]),
    async getChartList() {
      const params = {
        gen_ui: {
          request_type: "get_analysis_file_list",
        },
      };
      this.charts = await this.fetchChartList(params);
    },
    async updateValue() {
      if (this.chart.type === "json") {
        this.chart.jsonData = await this.getJsonData(this.chart.url);
      }
    },
    async getJsonData(url) {
      const data = await this.fetchJsonData(url);
      return data;
    },
  },
};
</script>

<style scoped lang="scss">
.charts-list {
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  max-width: 600px;
  .item-name {
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
  .item-data {
    img {
      width: 100%;
    }
  }
}
</style>
