<template>
  <div class="review">
    <div class="review__top">
      <div class="cell">
        <v-radio-group v-model="radioGroup" @change="changeRadioValue()" row>
          <v-radio label="Execution" value="execution_config"></v-radio>
          <v-radio label="Content" value="content_config"></v-radio>
          <v-radio label="General" value="general_config"></v-radio>
        </v-radio-group>
      </div>
      <div class="cell">
        <span class="cell__title">Select config:</span>
        <v-select
          v-if="configList && configList.filenames"
          @change="getConfigFile()"
          v-model="selectedConfig"
          :items="configList.filenames"
        ></v-select>
      </div>
    </div>
    <div class="review__form">
      <DynamicForm
        v-if="Object.keys(currentObject) && Object.keys(formVal)"
        :data="currentObject"
        :formVal="formVal"
        :formType="radioGroup"
      />
    </div>
    <div class="bottom-row">
      <div class="cell">
        <v-btn @click="saveConfig()" elevation="2">Save</v-btn>
        <div v-if="configPath">
          <v-btn @click="generationPipeline()" elevation="2">Generate</v-btn>
          <span>{{ configPath }}</span>
        </div>
      </div>
      <div class="cell">
        <v-btn @click="saveConfigToFile()">Save Config</v-btn>
        <div class="add-file">
          <v-file-input
            v-model="uploadConfig"
            accept="application/json"
            @change="selectJsonFile()"
            label="Select file"
          ></v-file-input>
        </div>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DynamicForm from "./DynamicForm";
import Loader from "@/components/global/Loader";

export default {
  name: "Generation",
  data: () => ({
    defaultJson: {},
    radioGroup: "execution_config",
    currentObject: {},
    formVal: {},
    configList: [],
    selectedConfig:
      "pipeline_config/configuration/default_content_generation_config.json",
    configPath:
      "pipeline_config/configuration/default_content_generation_config.json",
    uploadConfig: null,
    isLoading: false,
  }),
  components: { DynamicForm, Loader },
  watch: {
    defaultJson: {
      handler() {
        this.getFormValue();
      },
      deep: true,
    },
  },
  async created() {
    await this.getDefaultJson();
    await this.getFormValue();
    this.setCurrentConfigForEdit();
    await this.getConfigList();
  },
  methods: {
    ...mapActions("contentGeneration", [
      "fetchDefaultJson",
      "contentGenerationData",
    ]),
    async changeRadioValue() {
      await this.getFormValue();
      this.setCurrentConfigForEdit();
    },
    async getDefaultJson() {
      this.isLoading = true;
      const data = await this.fetchDefaultJson();
      let str = JSON.stringify(data)
        .replaceAll('"True"', "true")
        .replaceAll('"False"', "false");
      this.defaultJson = JSON.parse(str);
      this.isLoading = false;
    },
    async getFormValue() {
      this.isLoading = true;
      const params = {
        gen_ui: {
          request_type: "get_options_for_json_params",
          json_data: JSON.stringify(this.defaultJson),
          main_field: this.radioGroup,
        },
      };
      this.formVal = await this.contentGenerationData(params);
      this.isLoading = false;
    },
    setCurrentConfigForEdit() {
      this.currentObject = this.defaultJson[this.radioGroup];
    },
    async saveConfig() {
      const params = {
        gen_ui: {
          request_type: "save_content_generation_config",
          json_data: JSON.stringify(this.defaultJson)
            .replaceAll("true", '"True"')
            .replaceAll("false", '"False"'),
        },
      };
      this.configPath = await this.contentGenerationData(params);
      this.configList.filenames.push(this.configPath);
      this.selectedConfig = this.configPath;
    },
    async getConfigList() {
      const params = {
        gen_ui: {
          request_type: "get_configuration_file_list",
        },
      };
      this.configList = await this.contentGenerationData(params);
    },
    async getConfigFile() {
      this.defaultJson = {};
      this.configPath = this.selectedConfig;
      this.radioGroup = "execution_config";
      const params = {
        gen_ui: {
          request_type: "get_content_generation_config",
          config_path: this.selectedConfig,
        },
      };
      this.defaultJson = await this.contentGenerationData(params);
      await this.changeRadioValue();
    },
    async generationPipeline() {
      const params = {
        gen_ui: {
          request_type: "content_generation_pipeline",
          config_path: this.configPath,
        },
      };
      await this.contentGenerationData(params);
    },
    saveConfigToFile() {
      if (this.defaultJson) {
        let a = document.createElement("a");
        let file = new Blob([JSON.stringify(this.defaultJson)], {
          type: "application/json",
        });
        a.href = URL.createObjectURL(file);
        a.download = this.selectedConfig ? this.selectedConfig : "config.json";
        a.click();
      }
    },
    selectJsonFile() {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          if (reader.result) {
            this.defaultJson = JSON.parse(reader.result);
            this.getFormValue();
            this.setCurrentConfigForEdit();
          }
        },
        false
      );

      if (this.uploadConfig) {
        reader.readAsText(this.uploadConfig);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.review {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #f5f5f5;
  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    .cell {
      display: inline-flex;
      align-items: center;
      &:first-child {
        margin-right: 30px;
        flex-shrink: 0;
      }
      ::v-deep {
        .v-text-field {
          margin-top: 0;
          padding-top: 0;
        }
        .v-input--selection-controls {
          margin-top: 0;
          padding-top: 0;
        }
        .theme--light.v-messages {
          display: none;
        }
        .v-text-field__details {
          display: none;
        }
        .v-input__slot {
          margin-bottom: 0;
        }
      }
      &:last-child {
        width: 100%;
      }
      &__title {
        font-weight: 600;
        margin-right: 10px;
        display: inline-flex;
        flex-shrink: 0;
      }
    }
  }
  &__form {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .bottom-row {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    .cell {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      ::v-deep {
        .v-text-field__details {
          display: none;
        }
      }
      span {
        display: inline-flex;
        margin-top: 10px;
      }
      .add-file {
        width: 100%;
      }
    }
  }
}
</style>
