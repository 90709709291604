<template>
  <div class="list-wrapper" :class="{ nested: isNested }">
    <template v-for="(value, item, index) in data">
      <DynamicForm
        :key="index"
        :parentName="parentName ? parentName + '.' + item : item"
        :isNested="true"
        :formVal="formVal"
        :formType="formType"
        :data="value"
        :index="index"
        v-if="isObject(value)"
      />
      <div class="item" :key="index" v-else>
        <label>{{ parentName ? parentName + "." + item : item }}:</label>
        <div v-if="isArray(data[item])">
          <v-select
            v-if="isEditVal(parentName ? parentName + '.' + item : item)"
            chips
            multiple
            v-model="data[item]"
            :items="valueList(parentName ? parentName + '.' + item : item)"
          ></v-select>
          <v-select
            v-else
            chips
            multiple
            :value="data[item]"
            :items="data[item]"
          ></v-select>
        </div>
        <div v-else-if="isBoolean(data[item])">
          <v-checkbox
            v-model="data[item]"
            :label="`${data[item].toString()}`"
          ></v-checkbox>
        </div>
        <div v-else>
          <v-select
            v-if="isEditVal(parentName ? parentName + '.' + item : item)"
            v-model="data[item]"
            :items="valueList(parentName ? parentName + '.' + item : item)"
          ></v-select>
          <v-text-field
            v-else
            v-model="data[item]"
            hide-details="auto"
          ></v-text-field>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DynamicForm",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    formVal: {
      type: Object,
      default: () => {},
    },
    formType: {
      type: String,
      default: "",
    },
    isNested: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    parentName: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    formData: [],
    editValue: {},
  }),
  methods: {
    ...mapActions("contentGeneration", [
      "fetchFormData",
      "fetchGeneratedContentFileList",
    ]),
    isObject(item) {
      return typeof item === "object" && !Array.isArray(item) && item !== null;
    },
    isBoolean(item) {
      if (item === "True" || item === "False" || typeof item === "boolean") {
        return true;
      }
    },
    isArray(item) {
      return Array.isArray(item) && item !== null;
    },
    toFlatPropertyMap(obj, keySeparator = ".") {
      const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
        for (const [key, value] of Object.entries(obj)) {
          const property = parentProperty
            ? `${parentProperty}${keySeparator}${key}`
            : key;
          if (value && typeof value === "object" && !Array.isArray(value)) {
            flattenRecursive(value, property, propertyMap);
          } else {
            propertyMap[property] = value;
          }
        }
        return propertyMap;
      };
      return flattenRecursive(obj);
    },
    dataJsonPars() {
      this.formData = this.toFlatPropertyMap(this.data);
    },
    valueList(data) {
      const flatObj = this.toFlatPropertyMap(this.formVal);
      return flatObj[`${this.formType}.${data}`] || [];
    },
    isEditVal(path) {
      const flatObj = this.toFlatPropertyMap(this.formVal);
      let isValue = false;
      Object.keys(flatObj).forEach((key) => {
        if (key === `${this.formType}.${path}`) {
          isValue = true;
        }
      });
      return isValue;
    },
  },
};
</script>

<style scoped lang="scss">
.list-wrapper {
  display: flex;
  flex-direction: column;
  &:not(.nested) {
    > .list-wrapper.nested {
      margin-bottom: 10px;
      border-bottom: 1px solid #b42323;
    }
  }
}
.item {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  label {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
    width: auto;
    flex-shrink: 0;
  }
  div {
    width: 100%;
    max-width: 550px;
  }
  ::v-deep {
    .v-text-field__details {
      display: none;
    }
    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
    .v-input--switch,
    .v-text-field {
      margin-top: 0;
      padding-top: 0;
      .v-messages {
        display: none;
      }
    }
  }
}
</style>
